.tobii {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /* z-index: 99999999 !important; */
}

.tobii__slider {
    /* z-index: 99999999 !important; */
}

.tobii__close,
.tobii__prev,
.tobii__next {
    /* z-index: 99999999 !important; */
}
